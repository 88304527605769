import React, { Suspense } from 'react';
import './App.css';

// React.lazyを使用して、遅延ロードを実装
const PersonalHomepage = React.lazy(() => import('./PersonalHomepage'));

// フォールバックローディングコンポーネント
const FallbackLoading = () => (
  <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 flex justify-center items-center">
    <div className="h-16 w-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

function App() {
  return (
    <Suspense fallback={<FallbackLoading />}>
      <PersonalHomepage />
    </Suspense>
  );
}

export default App;